/* AboutUs.css */
.about-us-container {
    background-color: black;
    color: #ffffff;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
  }
  
  .hero-section {
    background: url('/public/img/Ibrahem--RabbitBlack.svg') no-repeat center center;
    background-size: cover;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
  }
  
  .hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(23, 41, 46, 0.7);
  }
  
  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    z-index: 1;
  }
  
  .main-title {
    font-size: 4.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #f57b18;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .subtitle {
    font-size: 1.8rem;
    margin-bottom: 30px;
    color: #ffffff;
  }
  
  .cta-button {
    display: inline-flex;
    align-items: center;
    background-color: #f57b18;
    color: #ffffff;
    padding: 15px 30px;
    border-radius: 50px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem;
    transition: all 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #ff8c00;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(245, 123, 24, 0.5);
  }
  
  .cta-button .icon {
    margin-left: 10px;
  }
  
  .client-value-section {
    padding: 100px 0;
    background: #ffffff;
  }
  
  .client-value-section .content-wrapper {
    display: flex;
    align-items: center;
    gap: 50px;
  }
  
  .text-content {
    flex: 1;
  }
  
  .text-content h2 {
    font-size: 2.5rem;
    color: #f57b18;
    margin-bottom: 30px;
    line-height: 1.2;
  }
  
  .text-content p {
    font-size: 1.1rem;
    color: black;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .image-content {
    flex: 1;
  }
  
  .client-image {
    width: 100%;
    border-radius: 15px;
  }
  
  .info-section {
    padding: 100px 0;
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  
  .info-card {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 20px;
    padding: 40px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .info-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(245, 123, 24, 0.2);
  }
  
  .card-icon {
    color: #f57b18;
    width: 60px;
    height: 60px;
    margin-bottom: 25px;
  }
  
  .info-card h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #f57b18;
  }
  
  .info-card p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  @media (max-width: 1024px) {
    .client-value-section .content-wrapper {
      flex-direction: column;
    }
  
    .info-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .main-title {
      font-size: 3.5rem;
    }
  
    .subtitle {
      font-size: 1.5rem;
    }
  
    .info-grid {
      grid-template-columns: 1fr;
    }
  
    .client-value-section,
    .info-section {
      padding: 60px 0;
    }
  }
  
  @media (max-width: 480px) {
    .main-title {
      font-size: 2.8rem;
    }
  
    .hero-section {
      height: 60vh;
    }
  
    .info-card {
      padding: 30px;
    }
  
    .text-content h2 {
      font-size: 2rem;
    }
  }