  :root {
    --primary-color: #f57b18;
    --secondary-color: black;
    --background-color: #f9f9f9;
    --white: #ffffff;
    --border-color: #ccc;
  }
  
  .page-title {
    text-align: center;
    font-size: 36px;
    color: var(--primary-color);
    margin: 40px 0;
  }
 
  
  .search-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 30px auto;
    padding: 5px; 
    max-width: 75%; /* Controls the maximum width of the search bar */
    
  }
  
  .search-bar input {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex-grow: 1; /* Allows the input to take up available space */
  }
  
  .search-bar button {
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-left:5px;
    background-color:#f57b18;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .research-page {
    width: 100%; /* This ensures that the container takes full width */
    min-height: 100vh; /* Optional: To make sure it takes minimum full height of the viewport */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This aligns children at the top and bottom */
  }
  
  .search-bar button:hover {
    background-color: #bf6012;
  }
  
  .research-hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 0;
    position: relative;
  }
  
  .hero-text {
    border-radius: 5px;
    max-width: 80%;
    margin-bottom: 20px;
  }
  
  .hero-image {
    width: 80%;
    height: auto;
    max-width: 1000px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .hero-text h1 {
    font-size: 24px;
    color: var(--primary-color);
  }
  
  .hero-text p {
    color: var(--secondary-color);
  }
  
  .research-section {
    padding: 40px 20px;
    background-color: black;
  }
  
  .research-section h2, .research-section h6 {
    text-align: center;
    color: var(--primary-color);
  }
  
  .alternative-fuel-options {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .option {
    background-color: var(--white);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    text-align: center;
    flex: 1;
    transition: transform 0.3s ease;
  }
  
  .option:hover {
    transform: scale(1.05);
    background-color: #ecf0f1;
  }
  
  .research-by-type {
    text-align: center;
  }
  
  .body-types {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .type {
    background-color: var(--white);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .type:hover {
    transform: scale(1.05);
  }
  
  .type img {
    height: 60px;
    margin-left: 10px;
  }
  
/* CSS for bottom half of page */
  .tools-section {
    padding: 40px 20px;
    background-color: var(--background-color);
  }
  
  .tools-section h2 {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .tools {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .tool {
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--border-color);
    padding: 20px;
    width: 30%;
    text-align: center;
  }
  
  .tool-icon img {
    width: 40px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .tool-text h3 {
    color: var(--primary-color);
    font-size: 18px;
  }
  
  .tool-text p {
    color: var(--secondary-color);
    font-size: 14px;
  }
  
  .brand-research-section {
    padding: 40px 20px;
    background-color: var(--white);
  }
  
  .brand-research-section h2 {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .brand-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    text-align: center;
    color: var(--primary-color);
  }
  
  .brand-list div {
    padding: 10px 0;
    font-size: 16px;
    cursor: default;
  }
  
  .brand-list div:hover {
    text-decoration: underline;
  }

  /* Full-width Footer CSS */
/* Root Variables */


/* Full-width Footer CSS */
.research-footer {
    width: 100vw; /* Ensures it spans the full viewport width */
    position: relative; /* Ensures it is not affected by ancestor containers */
    left: 50%; /* Shifts the element to the center */
    right: 50%;
    margin-left: -50vw; /* Moves it back half of its own width */
    margin-right: -50vw;
    background-color: var(--primary-color); /* Example background color */
    color: white; /* Text color */
    text-align: center;
    padding: 20px 0; /* Adjust padding as needed */
}

html, body{
    margin:0;
    padding:0;
    width:100%;
    overflow-x: hidden;
}