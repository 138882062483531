.container {
  max-width: 1200px;
  margin: 80px auto;
  padding: 0 15px;
}

.categories-header {
  font-size: 36px;
  text-align: center;
  padding-bottom: 10px;
}

.categories-wrapper {
  overflow: hidden;
  position: relative;
}

.categories-grid {
  display: flex;
  gap: 10px;
  animation: scroll 20s linear infinite; /* Faster scroll */
  width: var(--scroll-width, calc(250px * 10));
}

.category-item {
  min-width: 250px;
  flex-shrink: 0;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 200px;
}

.category-item img {
  max-width: 80%;
  max-height: 100px;
  margin-bottom: 10px;
  object-fit: contain;
}

.category-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.category-title {
  margin-top: auto;
  font-size: 16px;
  font-weight: 500;
  color: black;
  text-align: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * var(--scroll-width, 100%) / 2));
  }
}

/* Responsive grid for tablets */
@media (max-width: 768px) {
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    animation: none;
    width: auto;
    overflow-x: hidden;
  }
  
  .category-item {
    min-width: auto;
    height: 180px;
    transition: transform 0.2s ease;
  }

  .container {
    padding: 0 10px;
    max-width: 800px;
  }
}

/* Grid with two items per row on mobile */
@media (max-width: 480px) {
  .categories-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .category-item {
    min-width: auto;
    height: auto;
  }

  .category-item:hover {
    transform: scale(1.03);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .category-title {
    font-size: 14px;
  }

  .container {
    padding: 0 5px;
  }
}
