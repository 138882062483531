/* Footer.css */
html {
  scroll-behavior: smooth;
}

.footer {
    background-color: black;
    color: white;
    position: relative;
    width:100%;

    /* For all links within the footer (do not need text decorations) */
    a:link, a:visited{
      color:white;
      text-decoration: none;
    }
    
    a:hover{
      color:#f5cb87;
    }

    border-top: solid 2px #f57b18;
  }

  /* For links that currently lead to nowhere */
  .link-in-progress{
    pointer-events: none;
    cursor: default;
  }
  
  .footer__content p {
    display: inline;
    margin: 0;
    padding: 0;
  }

  /* Set the img (EVrabbit logo) to a certain size */
  .footer__content img {
    width: 35px;
    height: 35px;
    /* transform: translate(0, 13px) */
  }

  /* CSS for the top section of the footer */

  .joinEVrabbit{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap:wrap;
    margin: auto;
    width:95%;
  }

  .joinHeader{
    text-align: left;
    margin:10px;
  }

  .joinHeader h1{
    text-align:left;
    margin-bottom: 5px;
    font-size: 30px;
  }

  .joinHeader h2{
    font-size: 20px;
  }

  /* To make text pop out */
  .important{
    color:#f57b18
  }

  .joinSignUp{
    text-align:center;
  }

  .signInButton{
    border-radius: 4px;
    color:white;
    background-color:#f57b18;
    border-color:transparent;
    margin-top: 30px;
    margin-right: 145px;
  }

  .signInButton:hover{
    background-color:#c66415;
  }

  hr{
    border-color:rgb(143, 229, 229);
    width:100%;
    margin:auto;
    margin-top:5px;
    margin-bottom:5px;
  }

  /* CSS for the middle section of the footer */

  .footerLinks{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap:wrap;
    text-align: left;
    margin: 10px;
    margin: auto;
    width:95%;
  }

  .footerLinks ul{
    list-style-type: none;
  }

  .footerLinks li{
    margin-top:5px
  }

  .listLinks{
    margin-left:10px;
  }

  .listLinks h3 {
    color:#f57b18;
  }

  .contactLinks{
    vertical-align: middle;
    text-align: center;
    padding:auto;
    margin-left:10px;
    margin-right:10px;
  }

  .contactLinks h3{
    color:#f57b18
  }

  .contactLinks ul{
    text-align: center;
    margin-right:20px;
  }

  /* CSS for the bottom section of the footer */

  .important_info{
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap:wrap;
  }

  .copyright{
    text-align: center;
    margin-left:5px;
  }

  .important_links{
    text-align: right;
    margin:auto;
    margin-bottom:5px;
  }

  /* For making the dot between the links bigger */
  .bigDot{
    font-size:30px;
  }

  .important_links{
    a:link, a:visited{
      margin:10px;
    }
  }

  /* For tablets */
  @media (max-width: 785px) {
    .joinHeader{
      text-align: center;
    }

    .joinHeader h1{
      text-align: center;
    }

    .signInButton{
      margin-top:0px;
      margin-right:0px;
    }

    .joinEVrabbit{
      flex-direction: column;
    }

    .contactLinks{
      margin-top:10px;
      width:100%;
    }
  }

  /* for mobile devices */
  @media (max-width:480px){
    .listLinks{
      width:40%;
      margin-left:0px;
      margin-right:10px;
      margin-bottom:10px;
    }
  }