.car-search-container {
    padding: 16px;
    margin: 0;
    max-width: 100%;
    text-align: left;
  }
  
  .flex-container-car {
    display: flex;
    flex-direction: column; /* Stack vertically by default */
  }
  
  @media (min-width: 600px) {
    .flex-container-car {
      flex-direction: row; /* Switch to horizontal layout on wider screens */
    }
  }
  
  .filter-panel {
    flex: 1; /* Allow the filter panel to grow */
    min-width: 250px; /* Set a minimum width */
    margin-right: 16px; /* Spacing between filter and results */
  }
  
  .results-panel {
    flex: 3; /* Allow results panel to take more space */
  }
  
  .car-results-grid {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap */
    gap: 16px; /* Spacing between cards */
    justify-content: flex-start; /* Align items to the left */
    text-align: left;
  }
  
  .car-results-grid > div {
    flex: 1 1 calc(33.333% - 16px); /* Adjust cards to take 1/3 of the row with spacing */
    min-width: 250px; /* Minimum width for each card */
    max-width: 300px; /* Set a max width */
  }
  
  @media (max-width: 600px) {
    .car-results-grid > div {
      flex: 1 1 calc(100% - 16px); /* Full width on small screens */
    }
  }
  