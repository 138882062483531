.signup-form-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border-radius: 4px;
  }
  
  .signup-form {
    background-color: #fff;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    position: relative;
    border-radius: 8px;
  }
  
  .signup-form h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .close-btnn {
    position: absolute;
    width: 50px;
    top: -20px;
    right: 10px;
    background-color: #fff !important;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666 !important;
    max-width: 10%;
  }
  
  .signup-form form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-form label {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    text-align: end;
    color: #333;
  }
  
  .signup-form label span {
    min-width: 120px;
    margin-right: 1rem;
  }
  
  .signup-form input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    margin-right: 1rem;
  }
  
  .error-message {
    color: #ff0000;
    margin-bottom: 1rem;
  }
  
  .update-b {
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
  }
  
  .submit-butn:hover {
    background-color: #ec971f;
  }