/* HomePage.css */



/* General box-sizing for all elements */
*, *::before, *::after {
  box-sizing: border-box;
}
body{
  text-align: center;
 

}
/* Header styles */
.header {
  grid-area: header;
  width: 100%;
  position: relative; /* May be necessary for positioning child elements */
}

/* Ensure appropriate text and section styling */
h1 {
  text-align: center;
  margin-bottom: 20px;
}

/* Welcome message styling */
 section {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  overflow: visible;

  /* Prevent text overflow */
  overflow-wrap: break-word;
  /* older version of overflow-wrap for older browsers */
  word-wrap: break-word;  
  word-break: break-word;
  hyphens: auto;
}


/* Body and HTML styling to prevent horizontal overflow */
body, html {
  overflow-x: hidden;
  overflow-y: auto;
}

/* For any links in progress (disables them) */
.link-in-progress{
  pointer-events: none;
  cursor: default;
}

/* For any buttons in progress (disables clicking on them without impacting css) */
#button-in-progress{
  pointer-events: none;
  cursor: default;
}



